<template>
  <div class="amendments-container">
      <h1 style="margin-left: 20px;">{{$t('users')}}</h1>
        <div class="idcard-con">
          <div class="con mt-4">
            <vs-input class="search-input org-width" icon-after="search" :placeholder="$t('user-search')" v-model="user_search" icon="search" icon-no-border label-placeholder="icon-no-border" />
            <div class="outer-con2">
              <div class="table-container" style="overflow-y: auto; max-width: 100%;">
                <table>
                    <thead>
                        <tr>
                          <th @click="sort('username')">{{$t("users")}}<div class="arrow" v-if="'username' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                          <th colspan="2" @click="sort('email')">{{$t("email-adress")}}<div class="arrow" v-if="'email' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user.id" @click="editUser(user)">
                            <td>{{user.username}} <!--{{user.last_name}}, {{user.first_name}} --></td>
                            <td>{{user.email}}</td>
                            <td><vs-chip v-if="user.is_customer_admin" color="dark">Portal-Admin</vs-chip></td>
                        </tr>
                    </tbody>
                </table>
              </div>
          </div>
        </div>
      </div>
      <vs-button class="organ-btn" color="primary"  @click="addUser()"
        type="filled" icon="add">{{$t('user-new')}}</vs-button>

       <PopupUser ref="popup" />

       
    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">
      <H1>Users</H1>
        {{users}}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PopupUser from '@/components/PopupUser.vue'


export default Vue.extend({
  name: 'Users',
  components:{
      PopupUser
  },

  async mounted() {
    await this.$store.dispatch('organization/loadOrganizations')
    await this.$store.dispatch('user/loadUsers')
  },

  computed: {
    users:function() {
      let users = this.$store.getters['user/getUsers']
      if (this.user_search !== '') {
        users.filter(u => {
          return (((u.username).toLowerCase()).includes((this.user_search).toLowerCase()) || 
          ((u.email).toLowerCase()).includes((this.user_search).toLowerCase()))
        });     
      }
      return users.sort((a,b)=> {
          let modifier=1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
      })
    },
    organizations() {
      return this.$store.getters['organization/getOrganizations']
    },
  },

  methods:{
    sort:function(s) {
        if(s === this.currentSort) {
          this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          this.ascending = !this.ascending;
        }
        this.currentSort = s;
    },
    addUser(){
      const popup=this.$refs.popup ;
      popup.open();
    },

    editUser(user){
      const popup=this.$refs.popup ;
      popup.openEdit(user.id);
    }

  },
  data(){
      return{
          user_search:'',
          ascending: false,
          currentSort:'username',
          currentSortDir:'asc'
      }
  }
});

</script>
