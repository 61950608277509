<template>
  <vs-popup v-if="user" :title="title" :active.sync="showPopup">
    <div :class="{ popup: true, 'p-4': true }">

    <!-- NAVIGATION -->
    <vs-row class="menu">
      
      <div class="item me-2 p-2" :class="{'active': menu ==='general'}" @click="menu='general'">
        <span>{{$t('general')}}</span>
      </div>
      <div class="item me-2 p-2" :class="{'active': menu ==='permissions'}" @click="menu='permissions'">
        <span>{{$t('rights')}}</span>
      </div>
      <div class="item me-2 p-2" :class="{'active': menu ==='notifications'}" @click="menu='notifications'">
        <span>{{$t('Benachrichtigungen')}}</span>
      </div>
    </vs-row>
  
    <!-- GENERAL INFORMATION -->
    <vs-row vs-w="12" v-if="menu==='general'" class="content-section mt-4">
      <vs-col vs-w="6">
        <!-- <vs-row class="mb-2 mt-2">
          <vs-select class="simple-input w-100" label="Customer ID" v-model="user.customer_id">
            <vs-select-item :key="organization.id" :value="organization.customer_id" :text="organization.name" v-for="organization in organizations" />
          </vs-select>
        </vs-row> -->
        <vs-row class="mb-2 mt-4">
          <vs-input class="inputx w-100" autocomplete="off" :label-placeholder="$t('username')" v-model="user.username" />
        </vs-row>
        <vs-row class="mb-2 mt-2">
          <vs-input class="inputx w-100" autocomplete="off" :label-placeholder="$t('email-adress')" v-model="user.email" />
        </vs-row>
        <vs-row class="mb-2 mt-2">
          <vs-input class="inputx w-100" autocomplete="off" type="password" :label-placeholder="isEdit ? 'Passwort neu' : $t('password')" 
            v-model="user.password" />
        </vs-row>
        <vs-row class="mb-2 mt-2">
          <vs-input class="inputx w-100" autocomplete="off" type="password" :label-placeholder="$t('password-repeat')" v-model="user.password_confirm" />
        </vs-row>
        <vs-row vs-justify="space-between" class="mb-2 mt-4">
          <span>{{$t('portal-administrator')}}</span>
          <vs-switch :disabled="!hasUserPermission('admin')" @change="deleteOrgMappings()" v-model="user.is_customer_admin" />
        </vs-row>
      </vs-col>

      <vs-col vs-w="6">

        <vs-row class="ms-3">
          <vs-col vs-w="12" class="mt-4 ms-3">
            <div class="ms-3">
              <h5>Sicherheitsrichtlinien</h5> 
              <div class="list mt-2">
                  <p>- {{$t('user-popup1')}}</p>
                  <p>- {{$t('user-popup2')}}</p>
                  <p>- {{$t('user-popup3')}}</p>
                  <p>- {{$t('user-popup4')}}</p>
              </div>  
            </div>
  
          </vs-col>
        </vs-row>
      </vs-col>

    </vs-row>

    <!-- GENERAL INFORMATION -->
    <vs-row vs-w="12" v-if="!user.is_customer_admin && menu==='permissions'" class="content-section mt-4" >
      <vs-col vs-w="12">
        <h6 class="p-2" style="color:grey;" v-html="$t('legal-text')"></h6>
        <div class="table-container w-100">
          <table v-if="user.hasOwnProperty('organisation_mappings')">
            <thead>
              <tr>
                <th>{{$t('organization')}}</th>
                <th class="text-center">{{$t('ID-card')}}</th>
                <th class="text-center">{{$t('permits')}}</th>
                <th class="text-center">{{$t('organizations')}}</th>
                <!-- <th class="text-center">{{$t('users')}}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="organization, idx in organizations" :key="idx">
                <td>{{organization.name}}</td>
                <td><vs-checkbox
                  @change="setPermission($event, organization, 'idpass')"
                  :checked="hasPermission(organization, 'idpass')"></vs-checkbox></td>
                <td><vs-checkbox
                      @change="setPermission($event, organization, 'review')"
                      :checked="hasPermission(organization, 'review')"></vs-checkbox></td>
                <td><vs-checkbox
                      @change="setPermission($event, organization, 'organization')"
                      :checked="hasPermission(organization, 'organization')"></vs-checkbox></td>
                <!-- <td><vs-checkbox
                  :disabled="!hasUserPermission('account')"
                      @change="setPermission($event, organization, 'account')"
                      :checked="hasPermission(organization, 'account')"></vs-checkbox></td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" v-if="user.is_customer_admin && menu==='permissions'" class="content-section mt-4" >
      <h6 style="color:grey" class="mt-4 p-1">{{$t('portal-admin-legal-msg')}}</h6>
    </vs-row>

    <!-- NOTIFICATIONS -->
    <vs-row vs-w="12" v-if="menu==='notifications'" class="content-section mt-4" >
      <vs-col vs-w="12">
        <h6 class="p-2" style="color:grey;" v-html="$t('notifications-text')"></h6>
        <div class="table-container w-100">
          <table v-if="user.hasOwnProperty('organisation_mappings')">
            <thead>
              <tr>
                <th>{{$t('organization')}}</th>
                <th class="text-center">{{$t('permits')}}</th>
                <th class="text-center">{{$t('request-changes')}}</th>
                <th class="text-center">{{$t('intervall')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(aorganization, idx) in activeOrganizations" :key="idx">
                <td>{{aorganization.name}}</td>
                <td><vs-checkbox
                  @change="setPermission($event, aorganization, 'change')"
                  :checked="hasPermission(aorganization, 'change')"
                  ></vs-checkbox></td>
                <td><vs-checkbox
                  @change="setPermission($event, aorganization, 'idcard')"
                  :checked="hasPermission(aorganization, 'idcard')"
                  ></vs-checkbox></td>
                <td><vs-select :disabled="!hasPermission(aorganization, 'change') && !hasPermission(aorganization, 'idcard')"
                        class="selectExample" placeholder="Auswählen"
                        :value="selectedOption(aorganization)"
                        @input="onChange($event,aorganization)" >
                        <vs-select-item :key="key" v-for="(option, key) in intervalOptions" :value="option" :text="option" />
                    </vs-select>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </vs-col>
    </vs-row>

    <!-- <vs-row vs-w="12" v-if="user.is_customer_admin && menu==='notifications'" class="content-section mt-4" >
      <h6 style="color:grey" class="mt-4 p-1">{{$t('portal-admin-legal-msg')}}</h6>
    </vs-row> -->


    <!-- Controls -->
    <vs-row vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <vs-button v-if="isEdit && menu==='general'" class="pop-btn" color="danger" type="filled" icon="delete" @click="deleteConfirm(user)">{{$t("delete")}}</vs-button>
      </vs-col>

      <vs-col vs-w="6">
        <vs-row vs-type="flex" vs-justify="right" vs-align="right" >
          <vs-button v-if="isEdit" class="pop-btn" color="primary" type="filled" icon="done" @click="updateUser(user)"> {{$t("save")}}</vs-button>
          <vs-button v-else class="pop-btn" color="primary" type="filled" icon="done" @click="addUser()"> {{$t("create")}}</vs-button>
          <vs-button class="pop-btn" color="primary" type="filled" icon="close" @click="showPopup=!showPopup">{{$t("cancel")}}</vs-button>
        </vs-row>
      </vs-col>
    </vs-row>




    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">
      <h1>USER</h1>
      {{user}}
      <h1>CREATING USER</h1>
      {{creatingUser}}
    </div>
  </div>
  </vs-popup>
</template>

<script>
  import Vue from "vue";
  import _ from "lodash";
  import validators from "@/plugins/inputValidators";
  import errorNotifier from "@/plugins/errorNotifier";
import { exit } from "process";

  export default Vue.extend({
    props: ['modelValue'],
    emits: ['update:modelValue'],
    async mounted() {
      this.initUserObj();
      await this.$store.dispatch("organization/loadOrganizations");
    },

    computed: {
      account() {
        return this.$store.getters["auth/getUser"];
      },
      organizations() {
        return this.$store.getters["organization/getOrganizations"];
      },
      activeOrganizations:function() {
        let org = this.$store.getters["organization/getOrganizations"];
        if (this.user.is_customer_admin) {
          return org;
        } else {
          if (this.user.organisation_mappings) {
            return org.filter(u => {
              return this.user.organisation_mappings.find(o => {
                if (o.organisation_id === u.id) {
                  return true
                }
              });
            });
          } else {
            return null;
          }
        }
      },
      title() {
        if (this.isEdit) {
          return "Benutzer bearbeiten > " + this.user.username
        } else {
          return "Benutzer anlegen"
        }
      },
      creatingUser() {
        return this.$store.getters["auth/getUser"];
      },
    },
    data() {
      return {
        menu: "general",
        showPopup: false,

        isEdit: false,
        user: {},
        value: "",
        errorMessage: "",
        // account:{},

        intervalOptions: ["täglich","wöchentlich"],
      };
    },
    methods: {
      onChange(event, org) {
        this.user.organisation_mappings.find( o => {
          if (o.organisation_id === org.id) {
            if (event==="täglich") {
              o.notification_interval= { "days" : 1 };
            }
            if (event==="wöchentlich") {
              o.notification_interval= { "days" : 7 };
            }
          }
        })
      },
      selectedOption:function(org) {
        const orga = this.user.organisation_mappings.find(o => {
           if (o.organisation_id === org.id) {
            return true
           }
        })
        if (!orga) {
          return null;
        }
        if (!orga.notification_interval) {
          return null;
        } else {
          if (orga.notification_interval.days===1) {
            return "täglich";
          }
          if (orga.notification_interval.days===7) {
            return "wöchentlich";
          }
        }
      },
      initUserObj() {
        this.user = {
          username: "",
          email: "",
          is_customer_admin: false,
          password: "",
          password_confirm: "", 
          organisation_mappings: [],
        };
      },

      open() {
        this.initUserObj();
        this.showPopup = true;
        this.isEdit = false;
      },

      async openEdit(userId) {
        this.showPopup = true;
        this.isEdit = true;

        await this.$store.dispatch("user/loadUser", userId);
        const user = this.$store.getters["user/getUser"];

        this.user = null;
        this.user = _.cloneDeep(user);

        //Get Account Informations
        // const account = this.$store.getters["auth/getUser"];
        // this.account = null;
        // this.account = _.cloneDeep(account);
      },

      async addUser() {
        if (!this.validateInputs()) {
          return;
        }

        let status = await this.$store.dispatch("user/createUser", this.user);
        if (status === 201) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Benutzer wurde hinzugefügt",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },

      async updateUser(user) {
        if (!this.validateInputs()) {
          return;
        }

        let status = await this.$store.dispatch('user/updateUser', this.user)
        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Benutzer wurde gespeichert",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },

      deleteConfirm(user) {
        this.user = user
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Löschen bestätigen`,
          text: "Wollen Sie wirklich den aktuellen Benutzer unwiderruflich löschen?",
          accept: this.deleteUser,
          acceptText: "Löschen",
          cancelText: "Abbrechen"
        });
      },


      async deleteUser() {
        const user = this.user
        let status = await this.$store.dispatch("user/deleteUser", user.id);

        if (status === 200) {
          this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: "Benutzer wurde gelöscht",
          });
        } else {
          errorNotifier(status)
        }

        this.showPopup = false;
      },
      deleteOrgMappings() {
        if (this.user.is_customer_admin) {
          this.user.organisation_mappings = [];
        } else {
          this.user.organisation_mappings = [];
        }
      },
      hasUserPermission(permission) {
        // Check if Organization is in order_mappings
        if (permission === "admin") {
            return this.account.is_customer_admin
        }
        if (this.account.is_customer_admin) {
          return true
        }
        const orga_permissions = this.account.organisation_mappings.find(o => {
          if (o.id_card_permission) {
            return true
           }
           if (o.review_permission) {
            return true
           }
           if (o.organisation_permission) {
            return true
          }
          if (o.account_permission) {
            return true
          }
        })
        //console.log(orga_permissions);
        if (!orga_permissions) {
          return false
        }
        if (permission === "idpass") {
            return orga_permissions.id_card_permission
        }
        if (permission === "review") {
            return orga_permissions.review_permission
        }
        if (permission === "organization") {
          return orga_permissions.organisation_permission
        }
        if (permission === "account") {
          return orga_permissions.account_permission
        }
      },
      hasPermission(organization, permission) {
        // Check if Organization is in order_mappings
        const orga_permissions = this.user.organisation_mappings.find(o => {
           if (o.organisation_id === organization.id) {
            return true
           }
        })
        if (!orga_permissions) {
          return false
        }
        if (permission === "idpass") {
            return orga_permissions.id_card_permission
        }
        if (permission === "review") {
            return orga_permissions.review_permission
        }
        if (permission === "organization") {
          return orga_permissions.organisation_permission
        }
        if (permission === "account") {
          return orga_permissions.account_permission
        }
        if (permission === "change") {
          return orga_permissions.notification_change_request      
        }
        if (permission === "idcard") {
          return orga_permissions.notification_id_card      
        }
        return false
      },
      setPermission(event, organization, permission) {
        let permissionValue = null;
        if(permission !== "intervall") {
          permissionValue = event.target.checked;
        }
        // Check if Organization is in order_mappings
        const hasOrganization = this.user.organisation_mappings.find(o => o.organisation_id === organization.id)

        // Add Organization to order_mappings
        if (!this.user.is_customer_admin && hasOrganization===undefined) {
          this.user.organisation_mappings.push({
            account_id: this.user.id,
            organisation_id: organization.id,
            id_card_permission: false,
            review_permission: false,
            organisation_permission: false,
            account_permission: false,
            notification_change_request:false,
            notification_id_card:false,
            notification_interval: null
          })
        }
       // ADD ID CARD PERMISSION
       if (permission==="idpass") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              o.id_card_permission = permissionValue;
              if (!permissionValue) {
                o.review_permission = permissionValue;
              }
            }
          })
        }
        // ADD REVIEW PERMISSION
        if (permission==="review") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              o.review_permission = permissionValue;
              o.id_card_permission = permissionValue;
            }
          })
        }
        // ADD ORGANIZATION PERMISSION
        if (permission==="organization") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              o.organisation_permission = permissionValue
            }
          })
        }

        // ADD ACCOUNT PERMISSION
        if (permission==="account") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              o.account_permission = permissionValue
            }
          })
        }

        if (permission==="change") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              o.notification_change_request = permissionValue
            }
          })
        }

        if (permission==="idcard") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              o.notification_id_card = permissionValue
            }
          })
        }

        if (permission==="change" || permission==="idcard") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              if(!o.notification_id_card && !o.notification_change_request) {
                o.notification_interval=null;
              }
            }
          })
        }

        if (permission==="intervall") {
          this.user.organisation_mappings.find( o => {
            if (o.organisation_id === organization.id) {
              if (event==="täglich") {
                o.notification_interval= { "days" : 1 };
              } 
              else if (event==="wöchentlich") {
                o.notification_interval= { "days" : 7 };
              } else {
                o.notification_interval= null;
              }
            }
          })
        }
        // Remove Organization if no permissions are set for an organization
        for (var i=0; i<this.user.organisation_mappings.length; i++) {
          var o = this.user.organisation_mappings[i]
          if (!o.id_card_permission && !o.review_permission && !o.organisation_permission && !o.account_permission) {
            this.user.organisation_mappings.splice(i, 1)
          }
        }
      },


      validateInputs() {
        //   Check Password
        // if (!(this.isEdit && !this.user.hasOwnProperty("password"))) {
        if (this.user.password) {
          let check = validators.isValidPassword(this.user.password, this.user.password_confirm);
          if (!check.isValid) {
            this.$vs.notify({
              color: "danger",
              position: "bottom-right",
              title: "Passwort fehlerhaft!",
              text: check.errorMessage,
            });
            return false;
          }
        }


        // Check Email
        if (!validators.isValidEmail(this.user.email)) {
          this.$vs.notify({
            color: "danger",
            position: "bottom-right",
            title: "Ungültige Email-Adresse",
            text: "Email-Adresse ist ungültig!",
          });
          return false;
        }

        // Check organisation mappings
        if (!this.user.is_customer_admin) {
          if (this.user.organisation_mappings.length === 0) {
            this.$vs.notify({
              color: "danger",
              position: "bottom-right",
              title: "Fehlende Berechtigungen",
              text: "Benutzer benötigt Berechtigungen!",
            });
            return false;
          } else {
            for(const element of this.user.organisation_mappings) {
              if(element.notification_id_card || element.notification_change_request) {
                if(!element.notification_interval) {
                  this.$vs.notify({
                    color: "danger",
                    position: "bottom-right",
                    title: "Fehlender Benachrichtigungs-Intervall",
                    text: "Benutzer hat kein Benachrichtigungs-Intverall!",
                  });
                  return false;
                }
              }
            }
          }
        }
        return true;
      },
    },
  });
</script>

<style lang="css">
.selectExample {
  margin: -10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%
}
@media (max-width: 500px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%
  }
}
</style>